const en = {
  btn: {
    registration: "Sign Up",
    openAccount: "Open Account",
    join: "Join",
    login: "Log In",
    register: "Sign Up",
    continue: "Continue",
    begin: "To begin",
  },
  menu: {
    home: "Home",
    markets: "Markets",
    about: "About Us",
    contacts: "Contacts",
    platform: "Platform",
    faq: "FAQ",
    trade: "Trade",
  },
  subMenu: {
    crypto: "Basics of trading",
    security: "Security and Protection",
  },
  form: {
    name: "Name",
    phone: "Phone number",
  },
  privacy: "Privacy Policy",
  assets: "Assets",
  support: "Support",
  leverageTo: "Shoulder up",
  depositFrom: "Deposit from",
  cities: ["Great Britain", "Cyprus", "Hong Kong"],
  popularAssets: "Popular assets",
  worksDay1: "Mon-Fri",
  worksDay2: "Sat-Sun",
  ourAddress: "Our address",
  client: "Client Agreement",
};

export default en;
