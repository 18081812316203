const ru = {
  btn: {
    registration: "Регистрация",
    openAccount: "Открыть аккаунт",
    join: "Присоедениться",
    login: "Войти",
    register: "Зарегистрироваться",
    continue: "Продолжить",
    begin: "Начать",
  },
  menu: {
    home: "Главная",
    markets: "Рынки",
    about: "О нас",
    contacts: "Контакты",
    platform: "Платформа",
    faq: "FAQ",
    trade: "Торговля",
  },
  subMenu: {
    crypto: "Основы торговли",
    security: "Безопасность и защита",
  },
  form: {
    name: "Имя",
    phone: "Номер телефона",
  },
  privacy: "Политика конфиденциальности",
  terms: "Политика конфиденциальности",
  assets: "Активы",
  support: "Техподдержка",
  leverageTo: "Плечо до",
  depositFrom: "Депозит от",
  cities: ["Великобритания", "Кипр", "Гонконг"],
  popularAssets: "Популярные активы",
  worksDay1: "Пн-Пт",
  worksDay2: "Сб-Вс",
  ourAddress: "Наш адрес",
  client: "Клиентское соглашение",
};

export default ru;
