<template>
  <RouterView />
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "EmptyLayout",
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 1200;
        return window.innerWidth < maxWidth;
      },
    });
  },
};
</script>

<style scoped></style>
